import React from 'react';
import Layout from './../components/layout';
import Meta from './../components/meta';
import { useStoryblok } from '../lib/storyblok';
import DynamicComponent from '../components/dynamicComponent';
import { Header } from '../components';

const Page = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);

	const components = story.content.body.map(blok => (
		<DynamicComponent key={blok._uid} blok={blok} path={location.pathname} storyId={story.id}/>
	));

	return (
		<Layout>
			<Meta />
			<Header color="white"/>
			<div className="min-h-screen pt-87 bg-secondary">
				{components}
			</div>
		</Layout>
	);
};

export default Page;
